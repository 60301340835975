












































































































































































import { WorkingPeriodResource } from '@/classes/WorkingPeriod.resource';
import { SelectItemOption } from '@/classes/base/elements';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { createDocx, getMeetOptions, getPossibleEndTime, copyInviteText } from '../helper/index';

@Component({
	components: {
		BaseSelect: () => import('../components/base/BaseSelect.vue'),
		BaseDialog: () => import('../components/base/BaseDialog.vue'),
		BaseButton: () => import('../components/base/BaseButton.vue')
	}
})
export default class MeetingsTable extends Vue {
	@Prop({ default: [] })
	workingPeriods!: WorkingPeriodResource[];

	meetToDelete: WorkingPeriodResource = null as unknown as WorkingPeriodResource;
	inviteMeet: WorkingPeriodResource = null as unknown as WorkingPeriodResource;
	public showActive = true;
	public confirmMeetDelete = false;
	public inviteDialog = false;
	public page = 1;
	public offset = 0;
	public search = '';

	async openMeet(id: number): Promise<void> {
		//status === 'template' ? this.$router.push(`/create/${id}`) : this.$router.push(`/meeting/${id}`);
		this.$router.push(`/meeting/${id}`);
	}

	getTableMeetOptions(workingPeriod: WorkingPeriodResource): SelectItemOption[] {
		return getMeetOptions(workingPeriod);
	}

	async removeMeet(): Promise<void> {
		this.$emit('delete-meet', this.meetToDelete);
	}
	async editMeet(id: number): Promise<void> {
		this.$router.push(`/create/${id}/edit`);
	}
	async templateCopy(id: number): Promise<void> {
		this.$router.push(`/create/${id}/template`);
	}

	async invite(workingPeriod: WorkingPeriodResource): Promise<void> {
		//createInvite(workingPeriod);
		this.inviteMeet = workingPeriod;
		this.inviteDialog = true;
	}

	async getInviteText(): Promise<void> {
		copyInviteText(this.inviteMeet);
	}

	async report(workingPeriod: WorkingPeriodResource): Promise<void> {
		createDocx(workingPeriod);
	}

	getNextDueWorkingPeriodEndTime(workingPeriod: WorkingPeriodResource): string {
		return getPossibleEndTime(workingPeriod);
	}

	get activeMeets(): WorkingPeriodResource[] {
		const result: WorkingPeriodResource[] = [];
		if (this.workingPeriods)
			this.workingPeriods.forEach((meet) => {
				if (meet.status !== 'finished') result.push(meet);
			});
		result.sort((a: WorkingPeriodResource, b: WorkingPeriodResource) => {
			return b.id - a.id;
		});

		return result;
	}

	get finishedMeets(): WorkingPeriodResource[] {
		const result: WorkingPeriodResource[] = [];
		if (this.workingPeriods)
			this.workingPeriods.forEach((meet) => {
				if (meet.status === 'finished') result.push(meet);
			});
		result.sort((a: WorkingPeriodResource, b: WorkingPeriodResource) => {
			return b.id - a.id;
		});
		return result;
	}

	get shortMeets(): WorkingPeriodResource[] {
		const short: WorkingPeriodResource[] = [];
		if (this.showActive && this.activeMeets.length > 0) {
			//active meets
			const short = this.activeMeets.slice(this.offset, this.offset + 5);
			return short;
		} else if (!this.showActive && this.finishedMeets.length > 0) {
			//finished meets
			const short = this.finishedMeets.slice(this.offset, this.offset + 5);
			return short;
		} else return short;
	}

	get searchedMeets(): WorkingPeriodResource[] | null {
		if (this.search.length > 0) {
			if (this.showActive && this.activeMeets.length > 0) {
				const search: WorkingPeriodResource[] = [];
				this.activeMeets.forEach((meet: WorkingPeriodResource) => {
					if (
						meet.title.toLowerCase().includes(this.search.toLowerCase()) ||
						new Date(meet.meetDay)
							.toLocaleDateString('de-DE', {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit'
							})
							.includes(this.search.toLowerCase())
					) {
						search.push(meet);
					}
				});
				return search;
			} else if (!this.showActive && this.finishedMeets.length > 0) {
				const search: WorkingPeriodResource[] = [];
				this.finishedMeets.forEach((meet: WorkingPeriodResource) => {
					if (
						meet.title.toLowerCase().includes(this.search.toLowerCase()) ||
						new Date(meet.meetDay)
							.toLocaleDateString('de-DE', {
								year: 'numeric',
								month: '2-digit',
								day: '2-digit'
							})
							.includes(this.search.toLowerCase())
					) {
						search.push(meet);
					}
				});
				return search;
			} else return null;
		} else return null;
	}
}
